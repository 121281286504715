<script>
const sidebarMenu = [
  { 
    path: '/dashboard', icon: 'fa fa-home', title: 'BERANDA', role: ['Administrator', 'Wajib Pajak', 'Bendahara']
  },
  {
    path: '/admin/npwpd', icon: 'fa fa-list', title: 'NPWPD', role: ['Administrator'],
    children: [
      { 
        path: '/admin/npwpd', icon: 'fa fa-list', title: 'NPWPD Provinsi', role: ['Administrator']
      },
      { 
        path: '/admin/wakil', icon: 'fa fa-user', title: 'Wakil NPWPD', role: ['Administrator']
      },
    ]
  },
  {
    path: '/admin/management', icon: 'fa fa-users', title: 'MANAGEMENT', role: ['Administrator'],
    children: [
      {
        path: '/admin/management/bentuk-badan', icon: 'fa fa-university', title: 'Bentuk Badan', role: ['Administrator']
      },
      {
        path: '/admin/management/status-registrasi', icon: 'fa fa-list', title: 'Status Registrasi', role: ['Administrator']
      },
      // {
      //   path: '/admin/management/users', icon: 'fa fa-user', title: 'User', role: ['Administrator']
      // }
    ]
  },
  // user section
  {
    path: '/user/npwpd', icon: 'fa fa-list', title: 'NPWPD', role: ['Wajib Pajak', 'Bendahara']
  },
  // {
  //   path: '/user/wakil/list', icon: 'fa fa-user', title: 'Wakil NPWPD', role: ['Wajib Pajak', 'Bendahara']
  // },
]

export default sidebarMenu;
</script>
