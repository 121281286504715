import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    //linkActiveClass: 'open active',
    scrollBehavior: () => ({ y: 0 }),
    routes,
    })

// default title
const DEFAULT_TITLE = 'E-PBBKB'

router.beforeEach((to, from, next) => {
    document.title = 'PAJAK | ' + to.meta.title || DEFAULT_TITLE;
    
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('EP_token') == null) {
        next({
            path: '/auth/login',
            params: { nextUrl: to.fullPath }
        })
        } else {
        // let user = JSON.parse(localStorage.getItem('user'))
        // if(to.matched.some(record => record.meta.is_admin)) {
        //   if(user.is_admin == 1){
        //       next()
        //   }
        //   else{
        //       //next({ name: 'userboard'})
        //   }
        // }
        // else if(to.matched.some(record => record.meta.is_user)) {
        //   if(user.is_admin == 0){
        //       next()
        //   }
        //   else{
        //       next({ name: 'admin'})
        //   }
        // }
            const role = JSON.parse(localStorage.getItem('roles'))[0]
            if (!to.matched.some(record => record.meta.access.includes(role))) {
                next({
                    path: '*',
                })
            } else {
                next()
            }
            next()
        }
    } else {
        next()
    }
    })

export default router