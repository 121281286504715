<template>
	<div>
		<!-- begin #header -->
		<div id="header" class="header navbar-default">
			<div class="p-5 h4">
				<div class="label label-inverse p-5">{{ date }}</div>
			</div>
			<!-- begin navbar-header -->
			<!-- <div class="navbar-header">
				<button type="button" class="navbar-toggle pull-left" v-on:click="toggleMobileRightSidebar" v-if="pageOptions.pageWithTwoSidebar">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button> -->
				<!-- <router-link to="/dashboard" class="navbar-brand">
					<span class="px-2"><b-img :src="imageLogo" width="30"></b-img> </span><b><span class="text-success">BPPRD</span></b>
				</router-link> -->
				<!-- <button type="button" class="navbar-toggle pt-0 pb-0 mr-0 collapsed" v-on:click="toggleMobileTopMenu" v-if="pageOptions.pageWithTopMenu && !pageOptions.pageWithoutSidebar">
					<span class="fa-stack fa-lg text-inverse">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
				</button>
				<button type="button" class="navbar-toggle" v-on:click="toggleMobileTopMenu" v-if="pageOptions.pageWithTopMenu && pageOptions.pageWithoutSidebar">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
				<button type="button" class="navbar-toggle p-0 m-r-0" v-on:click="toggleMobileMegaMenu" v-if="pageOptions.pageWithMegaMenu">
					<span class="fa-stack fa-lg text-inverse m-t-2">
						<i class="far fa-square fa-stack-2x"></i>
						<i class="fa fa-cog fa-stack-1x"></i>
					</span>
				</button>
				<button type="button" class="navbar-toggle" v-on:click="toggleMobileSidebar" v-if="!pageOptions.pageWithoutSidebar">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
			</div> -->
			<!-- end navbar-header -->
			
			<!-- <header-mega-menu v-if="pageOptions.pageWithMegaMenu"></header-mega-menu> -->
		
			<!-- begin header-nav -->
			<ul class="navbar-nav navbar-right">
				<b-nav-item-dropdown menu-class="navbar-language" no-caret  v-if="pageOptions.pageWithLanguageBar">
					<template slot="button-content">
						<span class="flag-icon flag-icon-us mr-1" title="us"></span>
						<span class="name d-none d-sm-inline mr-1">EN</span> <b class="caret"></b>
					</template>
					<b-dropdown-item href="javascript:;"><span class="flag-icon flag-icon-us" title="us"></span> English</b-dropdown-item>
					<b-dropdown-item href="javascript:;"><span class="flag-icon flag-icon-cn" title="cn"></span> Chinese</b-dropdown-item>
					<b-dropdown-item href="javascript:;"><span class="flag-icon flag-icon-jp" title="jp"></span> Japanese</b-dropdown-item>
					<b-dropdown-item href="javascript:;"><span class="flag-icon flag-icon-be" title="be"></span> Belgium</b-dropdown-item>
					<b-dropdown-divider class="m-b-0"></b-dropdown-divider>
					<b-dropdown-item href="javascript:;" class="text-center">more options</b-dropdown-item>
				</b-nav-item-dropdown>
				<b-nav-item-dropdown menu-class="dropdown-menu-right" class="dropdown navbar-user" no-caret>
					<template slot="button-content">
						<img :src="masinfo" alt="" /> 
						<span class="d-none d-md-inline" v-if="user">
							<span class="font-weight-bold">{{ user.name }}</span> | {{ user.roles[0].name}}
						</span><b class="caret"></b>
					</template>
					<b-dropdown-item to="/">Ganti Password</b-dropdown-item>
					<b-dropdown-divider></b-dropdown-divider>
					<b-dropdown-item @click="logout">Log Out</b-dropdown-item>
				</b-nav-item-dropdown>
				<li class="divider d-none d-md-block" v-if="pageOptions.pageWithTwoSidebar"></li>
				<li class="d-none d-md-block" v-if="pageOptions.pageWithTwoSidebar">
					<a href="javascript:;" v-on:click="toggleRightSidebarCollapsed" class="f-s-14">
						<i class="fa fa-th"></i>
					</a>
				</li>
			</ul>
			<!-- end header navigation right -->
		</div>
		<!-- end #header -->
	</div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import PageOptions from '../../config/PageOptions.vue'
import HeaderMegaMenu from './HeaderMegaMenu.vue'
import Logo from '@/assets/images/logoSumut.png'
import Masinfo from '@/assets/user.png'
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'Header',
	components: {
		HeaderMegaMenu
	},
  data() {
		return {
			pageOptions: PageOptions,
			imageLogo: Logo,
			masinfo: Masinfo,
			user: JSON.parse(localStorage.getItem('user')),
			date : null
		}
  },
  created () {
	  this.date = moment(Date.now()).format('D MMMM YYYY | hh:mm:ss')
	  setInterval(() => this.updateTime(), 1 * 1000)
  },
	methods: {
		dateFun () {
			setInterval(this.date, 1000)
		},
		toggleMobileSidebar() {
			this.pageOptions.pageMobileSidebarToggled = !this.pageOptions.pageMobileSidebarToggled;
		},
		toggleMobileRightSidebar() {
			this.pageOptions.pageMobileRightSidebarToggled = !this.pageOptions.pageMobileRightSidebarToggled;
		},
		toggleMobileTopMenu() {
			this.pageOptions.pageMobileTopMenu = !this.pageOptions.pageMobileTopMenu;
		},
		toggleMobileMegaMenu() {
			this.pageOptions.pageMobileMegaMenu = !this.pageOptions.pageMobileMegaMenu;
		},
		toggleRightSidebar() {
			this.pageOptions.pageRightSidebarToggled = !this.pageOptions.pageRightSidebarToggled;
		},
		toggleRightSidebarCollapsed() {
			this.pageOptions.pageRightSidebarCollapsed = !this.pageOptions.pageRightSidebarCollapsed;
		},
		checkForm: function(e) {
			e.preventDefault();
			this.$router.push({ path: '/extra/search' })
		},
		logout() {
			axios.get('/api/logout').then((response) => {
				if (response.data.error == false ) {
					localStorage.removeItem('EP_token')
					localStorage.removeItem('user')
					localStorage.removeItem('roles')
					localStorage.removeItem('permissions')
					this.$router.push('/auth/login')
					this.$store.dispatch("updateNpwpd", null)
				}
			}).catch(error => {
				console.log(error)
				if (error.response.status == 500) {
					this.$swal({
                        icon: "warning",
                        title: "Terjadi Masalah.",
                        text: "Silahkan hubungi Administrator Pajak Daerah.",
                        type: "warning",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        confirmButtonText: "OKE",
                        showCloseButton: true,
                        timer: 5000,
                    });
				} else if (error.response.status == 401) {
					localStorage.removeItem("EP_token");
					this.$router.push({ name: "Login" });
				} else if (error.response.status == 404) {
					this.$swal({
                        icon: "warning",
                        title: "Terjadi Masalah.",
                        text: "Link tidak dapat ditemukan.",
                        type: "warning",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        confirmButtonText: "OKE",
                        showCloseButton: true,
                        timer: 5000,
                    });
				}
			})
			// ---
			// this.$cookies.remove('XSRF-TOKEN' [path [domain]])
			// this.$cookies.remove('tngl_simaksi_session' [path [domain]])
			// this.$cookies.remove('XSRF-TOKEN')
			// this.$cookies.remove('tngl_simaksi_session')
      },
	  // time 
	  updateTime () {
		  this.date = moment(Date.now()).format('dddd, Do MMMM YYYY | hh:mm:ss')
	  }
	}
}
</script>
