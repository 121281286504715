<template>
	<ul class="nav">
		<li class="nav-profile">
			<a href="javascript:;" v-on:click="expand()">
				<div class="cover with-shadow"></div>
				<div class="info">
					<b-row>
						<b-col sm="3">
							<img :src="logo" alt="" width="40">
						</b-col>
						<b-col sm="9">
							<div class="pt-3">
								PAJAK DAERAH
							</div>
							<div style="font-size: 8px">
								PROVINSI SUMATERA UTARA
							</div>
						</b-col>
					</b-row>
				</div>
			</a>
		</li>
		<li>
			<ul class="nav nav-profile" v-bind:class="{ 'd-block': this.stat == 'expand' && !this.pageOptions.pageSidebarMinified, 'd-none': this.stat == 'collapse' }">
				<li><a href="javascript:;"><i class="fa fa-cog"></i> Settings</a></li>
				<li><a href="javascript:;"><i class="fa fa-pencil-alt"></i> Send Feedback</a></li>
				<li><a href="javascript:;"><i class="fa fa-question-circle"></i> Helps</a></li>
			</ul>
		</li>
	</ul>
</template>

<script>
import PageOptions from '../../config/PageOptions.vue'
import logo from '@/assets/images/logoSumut.png'

export default {
	name: 'SidebarNavProfile',
	data() {
		return {
			stat: '',
			pageOptions: PageOptions,
			logo: logo
		}
	},
	methods: {
		expand: function() {
			this.stat = (this.stat == 'expand') ? 'collapse' : 'expand'
		}
  }
}
</script>
