import Vue from 'vue'
import VueX from 'vuex'

// plugins
import VueRouter from 'vue-router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueNVD3 from 'vue-nvd3'
import VueInsProgressBar from 'vue-ins-progress-bar'
import VueEventCalendar from 'vue-event-calendar'
import VueSparkline from 'vue-sparklines'
import * as VueGoogleMaps from 'vue2-google-maps'
import Vueditor from '@agametov/vueditor'
import VueHljs from 'vue-hljs'
import VueSweetalert2 from 'vue-sweetalert2'
import VueNotification from 'vue-notification'
import VuePanel from './plugins/panel/'
import VueDateTimePicker from 'vue-bootstrap-datetimepicker'
import VueSelect from 'vue-select'
import VueDatepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js"
import VueMaskedInput from 'vue-maskedinput'
import VueInputTag from 'vue-input-tag'
import VueSlider from 'vue-slider-component'
import VueGoodTable from 'vue-good-table'
import VueFullCalendar from 'vue-full-calendar'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VueColorpicker from 'vue-pop-colorpicker'
import VueCustomScrollbar from 'vue-custom-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import DateRangePicker from 'vue2-daterange-picker'

// plugins css
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'nvd3/build/nv.d3.min.css'
import 'vue-event-calendar/dist/style.css'
import 'vue-hljs/dist/vue-hljs.min.css'
import '@agametov/vueditor/dist/style/vueditor.min.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import 'simple-line-icons/css/simple-line-icons.css'
import 'flag-icon-css/css/flag-icon.min.css'
import 'ionicons/dist/css/ionicons.min.css'
import 'vue-good-table/dist/vue-good-table.css'
import 'fullcalendar/dist/fullcalendar.css'
import 'vue-select/dist/vue-select.css'
import 'vue-slider-component/theme/antd.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'bootstrap-social/bootstrap-social.css'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

// color admin css
import './scss/vue.scss'

// color admin router
import router from './router/index'

// Import vForm
// ==================================================
import { Form, HasError, AlertError } from 'vform'

// snotify
import Snotify from 'vue-snotify'

// axios
import axios from 'axios'
import VueAxios from 'vue-axios'

import App from './App.vue'
import store from './store'
// import VueCompositionApi from '@vue/composition-api'

// html to paper
// import VueHtmlToPaper from 'vue-html-to-paper'
// vue gates
import VueGates from 'vue-gates'
// vue animate css
import VAnimateCss from 'v-animate-css'

Vue.config.productionTip = false

Vue.use(VueX)
Vue.use(VueRouter)
Vue.use(BootstrapVue, IconsPlugin)
Vue.use(VueNVD3)
Vue.use(VueEventCalendar, {locale: 'en'})
Vue.use(VueSparkline)
Vue.use(Vueditor)
Vue.use(VueHljs)
Vue.use(VueSweetalert2)
Vue.use(VueNotification)
Vue.use(VuePanel)
Vue.use(VueDateTimePicker)
Vue.use(VueGoodTable)
Vue.use(VueFullCalendar)
Vue.use(VueColorpicker)
Vue.use(VueGoogleMaps, {
  load: {
    key: '',
    libraries: 'places'
  }
})
Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '3px'
})

// composition api
// Vue.use(VueCompositionApi)

// snotify
Vue.use(Snotify)

// axios
Vue.use(VueAxios, axios)

Vue.component('v-select', VueSelect);
Vue.component('datepicker', VueDatepicker)
Vue.component('masked-input', VueMaskedInput)
Vue.component('input-tag', VueInputTag)
Vue.component('vue-slider', VueSlider)
Vue.component('vue-custom-scrollbar', VueCustomScrollbar)
Vue.component('apexchart', VueApexCharts)
Vue.component('date-range-picker', DateRangePicker)
Vue.component(VueCountdown.name, VueCountdown);

// use vForm
// ==================================================
window.Form = Form
Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)

// axios config
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API
// axios.defaults.baseURL = 'http://localhost:8000/'
// axios.defaults.baseURL = 'https://pajak.sumutprov.go.id/induk-api'
// axios.defaults.baseURL = 'http://192.168.100.29:8000/'

// axios token header
// if (token) {
//   axios.defaults.headers.common.Authorization = token
// }
axios.interceptors.request.use(
  config => {
    const token = 'Bearer ' + localStorage.getItem('EP_token')
    config.headers.authorization = token
    return config
  },
  error => Promise.reject(error)
)

// const options = {
//   name: '_blank',
//   specs: [
//     'fullscreen=yes',
//     'titlebar=yes',
//     'scrollbars=yes'
//   ],
//   styles: [
//     'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
//     'https://unpkg.com/kidlat-css/css/kidlat.css'
//   ]
// }

// vue html to paper
// Vue.use(VueHtmlToPaper, options);
// vue gates 
Vue.use(VueGates)
// vue animate css
Vue.use(VAnimateCss)

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')