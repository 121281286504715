// authentication
const Login = () => import(/* webpackChunkName: "login" */ '@/views/auth/Login')
const Logout = () => import(/* webpackChunkName: "logout" */ '@/views/auth/Logout')
const Daftar = () => import(/* webpackChunkName: "daftar" */ '@/views/auth/Register')
const EmailReset = () => import(/* webpackChunkName: "reset" */ '@/views/auth/EmailResetPassword')
const Reset = () => import(/* webpackChunkName: "reset" */ '@/views/auth/ResetPassword')
const ResetDone = () => import(/* webpackChunkName: "resetdone" */ '@/views/auth/ResetPasswordDone')

// Dashboard
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard')

// NPWP - ADMIN
const NPWPProv = () => import(/* webpackChunkName: "npwpprovinsi" */ '@/views/admin/npwpd/ListNPWPProv')
// const TambahNPWP = () => import(/* webpackChunkName: "tambahnpwp" */ '@/views/npwpd/TambahNPWP')
const DetailNPWP = () => import(/* webpackChunkName: "tambahnpwp" */ '@/views/admin/npwpd/DetailNPWP')
const EditNPWP = () => import(/* webpackChunkName: "editnpwp" */ '@/views/admin/npwpd/EditNPWP.vue')

 // npwp subjek pajak
//  const ListSubjekPajakNpwp = () => import(/* webpackChunkName: "listsubjekpajak" */ '@/views/npwpd/subjek_pajak/ListSubjekPajak') 
//  const TambahSubjekPajakNpwp = () => import(/* webpackChunkName: "tambahsubjekpajak" */ '@/views/npwpd/subjek_pajak/TambahSubjekPajak') 
//  const EditSubjekPajakNpwp = () => import(/* webpackChunkName: "editsubjekpajak" */ '@/views/npwpd/subjek_pajak/EditSubjekPajak') 
//  const DetialSubjekPajakNpwpd = () => import(/* webpackChunkName: "detailsubjekpajak" */ '@/views/npwpd/subjek_pajak/DetailSubjekPajakNpwpd') 

 // npwp alamat subjek pajak
//  const ListAlamatSubjekPajakNpwp = () => import(/* webpackChunkName: "listalamatsubjekpajak" */ '@/views/npwpd/alamat_subjek_pajak/ListAlamatSubjekPajak') 
//  const TambahAlamatSubjekPajakNpwp = () => import(/* webpackChunkName: "tambahalamatsubjekpajak" */ '@/views/npwpd/alamat_subjek_pajak/TambahAlamatSubjekPajak') 
//  const EditAlamatSubjekPajakNpwp = () => import(/* webpackChunkName: "editalamatsubjekpajak" */ '@/views/npwpd/alamat_subjek_pajak/EditAlamatSubjekPajak') 

 // npwpd wakil
 const ListWakilNpwpd = () => import(/* webpackChunkName: "listwakilnpwpd" */ '@/views/admin/wakil/ListWakilNpwpd') 
 const DetailWakilNpwpd = () => import(/* webpackChunkName: "detailwakilnpwpd" */ '@/views/admin/wakil/DetailWakilNpwpd') 
 const EditWakilNpwpd = () => import(/* webpackChunkName: "editwakilnpwpd" */ '@/views/admin/wakil/EditWakilNpwpd.vue')

 // ganti password
// const GantiPassword = () => import(/* webpackChunkName: "gantipassword" */ '@/views/auth/GantiPassword.vue')

// Bentuk Badan - admin
const ListBentukBadan = () => import(/* webpackChunkName: "listbentukbadan" */ '@/views/admin/management/bentuk_badan/ListBentukBadan.vue')
const TambahBentukBadan = () => import(/* webpackChunkName: "tambahbentukbadan" */ '@/views/admin/management/bentuk_badan/TambahBentukBadan.vue')
const EditBentukBadan = () => import(/* webpackChunkName: "editbentukbadan" */ '@/views/admin/management/bentuk_badan/EditBentukBadan.vue')

// Status Registrasi
const ListStatusRegistrasi = () => import(/* webpackChunkName: "liststatusregistrasi" */ '@/views/admin/management/status_registrasi/ListStatusRegistrasi.vue')
const TambahStatusRegistrasi = () => import(/* webpackChunkName: "tambahstatusregistrasi" */ '@/views/admin/management/status_registrasi/TambahStatusRegistrasi.vue')
const EditStatusRegistrasi = () => import(/* webpackChunkName: "editstatusregistrasi" */ '@/views/admin/management/status_registrasi/EditStatusRegistrasi.vue')

// Users
const ListUsers = () => import(/* webpackChunkName: "listleveluser" */ '@/views/admin/management/users/ListUsers.vue')
const TambahUsers = () => import(/* webpackChunkName: "tambahleveluser" */ '@/views/admin/management/users/TambahUsers.vue')
const EditUsers = () => import(/* webpackChunkName: "editleveluser" */ '@/views/admin/management/users/EditUsers.vue')

// Dashboard - User
const DetailNpwpdUser = () => import(/* webpackChunkName: "detailnpwpduser" */ '@/views/user/npwpd/DetailNpwpdUser.vue')
const StepProfil = () => import(/* webpackChunkName: "stepprofil" */ '@/components/subjek_pajak/user_dashboard/StepProfil.vue')
const StepPimpinan = () => import(/* webpackChunkName: "steppimpinan" */ '@/components/subjek_pajak/user_dashboard/StepPimpinan.vue')
const StepPenanggungJawab = () => import(/* webpackChunkName: "steppenanggungjawab" */ '@/components/subjek_pajak/user_dashboard/StepPenanggungJawab.vue')
const DashboardPajak = () => import(/* webpackChunkName: "dashboardpajak" */ '@/components/subjek_pajak/user_dashboard/DashboardPajak.vue')

// const TambahNpwpdUser = () => import(/* webpackChunkName: "tambahnpwpduser" */ '@/views/user/npwpd/TambahNpwpdUser.vue')

    // Wakil Npwpd User
const ListWakilUser = () => import(/* webpackChunkName: "listnpwpduser" */ '@/views/user/wakil/ListWakilUser.vue')
const DetailWakilUser = () => import(/* webpackChunkName: "editwakiluser" */ '@/views/user/wakil/DetailWakilUser.vue')
const TambahWakilNpwpd = () => import(/* webpackChunkName: "tambahwakilnpwpd" */ '@/views/user/wakil/TambahWakilNpwpd.vue')

// error 404
const Page404 = () => import(/* webpackChunkName: "404" */ '@/views/error/Page404.vue')

const routes = [
    // Pajak Provinsi Sumatera Utara
    // AUTH
    // ==================================================
    {
        path: '/auth',
        redirect: '/auth/login',
        name: 'Auth',
        component: {
        render(c) {
            return c('router-view')
        }
        },
        children: [{
            path: 'login',
            name: 'Login',
            component: Login,
            meta: {
            title: 'Login'
            },
        },
        {
            path: 'logout',
            name: 'Logout',
            component: Logout,
            meta: {
            title: 'Logout'
            },
        },
        {
            path: 'daftar',
            name: 'Daftar',
            component: Daftar,
            meta: {
            title: 'Daftar Pengguna Baru'
            },
        },
        {
            path: 'lupa-password',
            name: 'EmailReset',
            component: EmailReset,
            meta: {
                title: 'Lupa Password',
                auth: false
            },
        },
        {
            path: 'password/reset/:token',
            name: 'Reset',
            component:  Reset,
            meta: {
                title: 'Reset Password',
                auth: false
            },
        },
        {
            path: 'reset-password-done',
            name: 'ResetDone',
            component:  ResetDone,
            meta: {
                title: 'Berhasil Reset Password',
                auth: false
            },
        }
        ]
    },

    {
        path: '/',
        redirect: '/dashboard'
    },

    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta:{
            title: 'Dashboard',
            requiresAuth: true,
            access: ['Administrator', 'Maintenance', 'Wajib Pajak']
        }
    },


    // Home Page Base on Role
    {
        path: '/admin',
        redirect: '/admin/npwpd',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            access: ['Administrator', 'Maintenance']
        },
        children: [
            // Npwpd Provinsi - admin
            {
                path: 'npwpd',
                redirect: '/admin/npwpd',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                meta: {
                    access: ['Administrator', 'Maintenance']
                },
                children: [
                    {
                        path: '/',
                        name: 'NpwpProv',
                        component: NPWPProv,
                        meta: {
                            title: 'List NPWPD',
                            requiresAuth: true,
                            access: ['Administrator', 'Maintenance']
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'EditNPWP',
                        component: EditNPWP,
                        meta: {
                            title: 'Edit NPWPD',
                            requiresAuth: true,
                            access: ['Administrator', 'Maintenance']
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'DetailNPWP',
                        component: DetailNPWP,
                        meta: {
                            title: 'Detail NPWPD',
                            requiresAuth: true,
                            access: ['Administrator', 'Maintenance']
                        }
                    }
                ]
            },

            // admin wakil npwpd
            {
                path: 'wakil',
                redirect: '/admin/wakil/list',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                meta: {
                    access: ['Administrator', 'Maintenance']
                },
                children: [
                    {
                        path: 'list',
                        name: 'ListWakilNpwpd',
                        component: ListWakilNpwpd,
                        meta: {
                            title: 'List Wakil NPWPD',
                            requiresAuth: true,
                            access: ['Administrator', 'Maintenance']
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'EditWakilNpwpd',
                        component: EditWakilNpwpd,
                        meta: {
                            title: 'Edit Wakil NPWPD',
                            requiresAuth: true,
                            access: ['Administrator', 'Maintenance']
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'DetailWakilNpwpd',
                        component: DetailWakilNpwpd,
                        meta: {
                            title: 'Detail Wakil NPWPD',
                            requiresAuth: true,
                            access: ['Administrator', 'Maintenance']
                        }
                    }
                ]
            },
            
            // management - admin
            {
                path: 'management',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                meta: {
                    access: ['Administrator', 'Maintenance']
                },
                children: [

                    // bentuk badan
                    {
                        path: 'bentuk-badan',
                        redirect: '/admin/management/bentuk-badan/list',
                        component: {
                            render(c) {
                                return c('router-view')
                            }
                        },
                        children: [
                            {
                                path: 'list',
                                name: 'ListBentukBadan',
                                component: ListBentukBadan,
                                meta: {
                                    title: 'List Bentuk Badan',
                                    requiresAuth: true,
                                    access: ['Administrator', 'Maintenance']
                                }
                            },
                            {
                                path: 'tambah',
                                name: 'TambahBentukBadan',
                                component: TambahBentukBadan,
                                meta: {
                                    title: 'Tambah Bentuk Badan',
                                    requiresAuth: true,
                                    access: ['Administrator', 'Maintenance']
                                }
                            },
                            {
                                path: 'edit/:id',
                                name: 'EditBentukBadan',
                                component: EditBentukBadan,
                                meta: {
                                    title: 'Edit Bentuk Badan',
                                    requiresAuth: true,
                                    access: ['Administrator', 'Maintenance']
                                }
                            }
                        ]
                    },

                     // status registrasi
                     {
                        path: 'status-registrasi',
                        redirect: '/admin/management/status-registrasi/list',
                        component: {
                            render(c) {
                                return c('router-view')
                            }
                        },
                        meta: {
                            access: ['Administrator', 'Maintenance']
                        },
                        children: [
                            {
                                path: 'list',
                                name: 'ListStatusRegistrasi',
                                component: ListStatusRegistrasi,
                                meta: {
                                    title: 'List Status Registrasi',
                                    requiresAuth: true,
                                    access: ['Administrator', 'Maintenance']
                                }
                            },
                            {
                                path: 'tambah',
                                name: 'TambahStatusRegistrasi',
                                component: TambahStatusRegistrasi,
                                meta: {
                                    title: 'Tambah Status Registrasi',
                                    requiresAuth: true,
                                    access: ['Administrator', 'Maintenance']
                                }
                            },
                            {
                                path: 'edit/:id',
                                name: 'EditStatusRegistrasi',
                                component: EditStatusRegistrasi,
                                meta: {
                                    title: 'Edit Status Registrasi',
                                    requiresAuth: true,
                                    access: ['Administrator', 'Maintenance']
                                }
                            }
                        ]
                    },

                    // users - admin
                    {
                        path: 'users',
                        redirect: '/admin/management/users/list',
                        component: {
                            render(c) {
                                return c('router-view')
                            }
                        },
                        meta: {
                            access: ['Administrator', 'Maintenance']
                        },
                        children: [
                            {
                                path: 'list',
                                name: 'ListUsers',
                                component: ListUsers,
                                meta: {
                                    title: 'List Users',
                                    requiresAuth: true,
                                    access: ['Administrator', 'Maintenance']
                                }
                            },
                            {
                                path: 'tambah',
                                name: 'TambahUsers',
                                component: TambahUsers,
                                meta: {
                                    title: 'Tambah Users',
                                    requiresAuth: true,
                                    access: ['Administrator', 'Maintenance']
                                }
                            },
                            {
                                path: 'edit/:id',
                                name: 'EditUsers',
                                component: EditUsers,
                                meta: {
                                    title: 'Edit Users',
                                    requiresAuth: true,
                                    access: ['Administrator', 'Maintenance']
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },

    // User - Dashboard
    {
        path: '/user',
        redirect: '/user/npwpd',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            access: ['Wajib Pajak']
        },
        children:[

           // Npwpd Provinsi - user
           {
            path: 'npwpd',
            redirect: '/user/npwpd',
            component: {
                render(c) {
                    return c('router-view')
                }
            },
            meta: {
                access: ['Wajib Pajak']
            },
            children: [
                    {
                        path: '/',
                        name: 'DetailNpwpdUser',
                        component: DetailNpwpdUser,
                        meta: {
                            title: 'List NPWPD',
                            requiresAuth: true,
                            access: ['Wajib Pajak']
                        }
                    },
                ]
            },

            // user wakil npwpd
            {
                path: 'wakil',
                redirect: '/admin/wakil/list',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                meta: {
                    access: ['Wajib Pajak']
                },
                children: [
                    {
                        path: 'list',
                        name: 'ListWakilUser',
                        component: ListWakilUser,
                        meta: {
                            title: 'List Wakil NPWPD',
                            requiresAuth: true,
                            access: ['Wajib Pajak']
                        }
                    },
                    {
                        path: 'detail/:id',
                        name: 'DetailWakilUser',
                        component: DetailWakilUser,
                        meta: {
                            title: 'Detail Wakil NPWPD',
                            requiresAuth: true,
                            access: ['Wajib Pajak']
                        }
                    },
                    {
                        path: 'tambah',
                        name: 'TambahWakilNpwpd',
                        component: TambahWakilNpwpd,
                        meta: {
                            title: 'Tambah Wakil NPWPD',
                            requiresAuth: true,
                            access: ['Wajib Pajak']
                        }
                    },
                ]
            },
            // step registrasi
            {
                path: 'reg',
                name: 'Registrasi',
                component: DashboardPajak,
                meta: {
                    access: ['Wajib Pajak']
                },
                children: [
                    {
                        path: 'profil',
                        name: 'StepProfil',
                        component: StepProfil,
                        meta: {
                            title: 'Registrasi Profil NPWPD',
                            requiresAuth: true,
                            isDisable: false,
                            access: ['Wajib Pajak']
                        },
                    },
                    {
                        path: 'pimpinan',
                        name: 'StepPimpinan',
                        component: StepPimpinan,
                        meta: {
                            title: 'Registrasi Pimpinan',
                            requiresAuth: true,
                            access: ['Wajib Pajak']
                        }
                    },
                    {
                        path: 'penanggung-jawab',
                        name: 'StepPenanggungJawab',
                        component: StepPenanggungJawab,
                        meta: {
                            title: 'Registrasi Penanggung Jawab',
                            requiresAuth: true,
                            access: ['Wajib Pajak']
                        }
                    },
                ]
    },
        ]
    },
    
    // Error Page
    { 
        path: '*', 
        name: 'Page404',
        component: Page404,
        meta: {
            title: 'Halaman tidak ditemukan'
        }
    },
]

export default routes