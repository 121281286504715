import Vue from 'vue'
import axios from 'axios'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      isDisable: false,
      isKirim: true,
      dataNpwpd: null,
  },
  mutations: {
      isDisable (state, value) {
          state.isDisable = value
      },
      isKirim (state, value) {
          state.isKirim = value
      },
      isNpwpd (state, data) {
          state.dataNpwpd = data
      },
  },
  actions: {
      isEventDisable ({commit}, value) {
          commit('isDisable', value)
      },
      isEventKirim ({commit}, value) {
         commit('isKirim', value)
      },
      updateNpwpd ({commit}, id) {
            if (id === null) {
                commit("isNpwpd", id)
            } else {
                axios.get("/api/wajib-pajak/npwpd/" + id).then(response => {
                    commit("isNpwpd", response.data.data)
                }).catch(error => {
                    console.log(error)
                }) 
            }
      },
  },
  modules: {
  }
})
